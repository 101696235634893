import { AppProps, AppContext } from "next/app";
import NextApp from "next/app";
import Head from "next/head";
import {
  MantineProvider,
  ColorScheme,
  ColorSchemeProvider,
} from "@mantine/core";
import { usePostHog } from "next-use-posthog";
import { getCookie, setCookie } from "cookies-next";
import { useState } from "react";
import Script from "next/script";
import { UserProvider } from "@auth0/nextjs-auth0";
import { useRouter } from "next/router";
import posthog, { PostHogConfig } from "posthog-js";
import { useEffect } from "react";
import { createPagesBrowserClient } from "@supabase/auth-helpers-nextjs";
import { SessionContextProvider, Session } from "@supabase/auth-helpers-react";
import * as Sentry from "@sentry/browser";
import GoogleTags from "../components/utils/GoogleTags";
import "../styles/globals.css";
import "../styles/button.css";

function MyApp(props: AppProps & { colorScheme: ColorScheme }) {
  const { Component, pageProps } = props;
  const [colorScheme, setColorScheme] = useState<ColorScheme>(
    props.colorScheme
  );
  const router = useRouter();
  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true, // Highly recommended as a minimum!!
        },
      },
      api_host: "https://a.claros.so",
      loaded: (posthog) => {
        if (process.env.NODE_ENV === "development") posthog.opt_out_capturing();
      },
    });

    const SENTRY_DSN =
      process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
    Sentry.init({
      dsn:
        SENTRY_DSN ||
        "https://e1900cd9481b45538a9b2ffc1ea57bde@o1169596.ingest.sentry.io/4505533999218688",
      integrations: [
        new posthog.SentryIntegration(posthog, "diva-labs", 4505533999218688),
      ],
    });

    const handlechat = (query, qapair, response) => {
      posthog.capture("chat generated", {
        query: query,
        history: qapair,
        response: response,
      });
    };
    function handlerec() {
      posthog.capture("rec");
    }
    function handlesearch() {
      posthog.capture("search");
    }
    // Track page views
    const handleRouteChange = () => posthog.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
        navigator.serviceWorker.register("/sw.js").then(
          (registration) => {
            console.log(
              "Service Worker registration successful:",
              registration
            );
          },
          (err) => {
            console.log("Service Worker registration failed:", err);
          }
        );
      });
    }
  }, []);
  // const { user } = pageProps;
  //     const toggleColorScheme = (value?: ColorScheme) => {
  //     const nextColorScheme = value || (colorScheme === 'dark' ? 'light' : 'dark');
  //     setColorScheme(nextColorScheme);
  //     setCookie('mantine-color-scheme', nextColorScheme, { maxAge: 60 * 60 * 24 * 30 });
  //   };

  return (
    <>
      <Head>
        <meta property="og:url" content="https://www.claros.so/" />
        <meta property="og:title" content="Claros" />
        <meta
          property="og:description"
          content="Claros is an AI shopper that helps you find what to buy"
        />
        <meta
          property="og:image"
          content="https://claros.so/another-preview.png"
        />
        <meta property="og:type" content="website" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.claros.so/" />
        <meta property="twitter:title" content="Claros" />
        <meta
          property="twitter:description"
          content="Find better products, faster"
        />
        <meta
          property="twitter:image"
          content="https://claros.so/another-preview.png"
        />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="generator" content="Framer 937e5b9" />
        <meta name="description" content="Find better products, faster" />
      </Head>
      <GoogleTags />
      <script
        dangerouslySetInnerHTML={{
          __html: `
                            function postIframeHeight() {
                                var actualHeight = document.body.scrollHeight; // get the actual page height
                                parent.postMessage(actualHeight, '*');
                            }

                            window.onload = postIframeHeight;  // when the iframe loads
                            window.onresize = postIframeHeight; // when the iframe content is resized

                            // Use MutationObserver to watch for changes in the iframe's content
                            var observer = new MutationObserver(function(mutations) {
                                postIframeHeight();
                            });

                            observer.observe(document.body, {
                                childList: true, // Report added/removed nodes
                                subtree: true,   // Also observe all descendants
                                attributes: true, // Also observe changes in attributes
                                characterData: true  // Also observe changes in text
                            });

                            `,
        }}
      ></script>

      {/* <ColorSchemeProvider colorScheme={colorScheme}>
                <UserProvider user={user}> */}
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ colorScheme }}
      >
        <Component {...pageProps} />
      </MantineProvider>
      {/* </ UserProvider>
            </ColorSchemeProvider> */}
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await NextApp.getInitialProps(appContext);
  return {
    ...appProps,
    colorScheme: getCookie("mantine-color-scheme", appContext.ctx) || "light",
  };
};

export async function getStaticProps(context) {
  return {
    props: {
      user: "",
    }, // will be passed to the page component as props
  };
}

export default MyApp;
